@use  "../../core-ui/_base"
//ABOUT

.about, .about-main
    padding: 1rem
    display: flex
    flex-direction: column
    gap: 2rem
    .download-btn
        font-size: 1rem
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center
        gap: 1rem
        cursor: pointer
        background-color: transparent
        border-width: 1px
        border-color: base.$pinkish
        border-style: solid
        letter-spacing: 1px
        padding: 0.5rem 0.2rem
        letter-spacing: 1px
        width: 15rem
        max-width: 75%
        font-family: base.$body-font
        color: base.$pinkish
        opacity: 0.9
        background: linear-gradient(to right, base.$white 50%, transparent 50%)
        background-size: 200% 100%
        background-position: right bottom
        transition: all 0.4s ease
        img
            width: 2rem
            height: 2rem

    h2
        font-size: 2.5rem
        line-height: 1.2em
       
        letter-spacing: 1px
    p
        font-size: 1.2rem
    
    h3 
        margin: 0
        font-family: base.$codingfont
        color: base.$pinkish

    h4 
        font-size: 1.2rem
        font-weight: 600
        letter-spacing: 1px
        color: base.$pinkish
        font-family: base.$codingfont
        margin-top: 0.75rem
        
    @media (min-width: base.$sm-width)
        h3
            font-size: 2rem
            gap: 0.7rem
            font-family: base.$codingfont
        h2
            font-size: 2.75rem
            margin: 0

    @media (min-width: base.$lg-width)
        display: grid
        grid-template-columns: repeat(2, 1fr)
        p
            margin-top: 2rem
.about
    display: grid
    grid-template-columns: 1fr

.about-main
    display: flex
    flex-direction: column
    padding: 1rem
    grid-column: span 3

//STACK SECTION
.stack-section
    display: grid
    grid-template-columns: repeat(2, 1fr)
    column-gap: 2rem
    row-gap: 1rem
    // margin-top: 5%
    padding: 1rem

    li
        display: list-item
        color: base.$white
        font-family: base.$body-font
        line-height: 1.5rem
        text-align: left
        font-weight: 500
        font-size: 1.2rem
    li::marker
        // content: counter(li) " > "
        color: base.$pinkish
        animation: color 0.3s ease-in-out infinite

.stack-section
    img
        width: 3rem
        max-width: 100%
        cursor: pointer
        transition: all 0.4s ease
    @media (min-width: base.$sm-width)
        gap: 1rem
//WORK EXPERIENCE
.work-experience, .education, .languages, .certification
    // margin-top: 5%
    //  margin: 5%
    display: grid
    grid-template-columns: 1fr
    color: base.$white
    gap: 1rem

    .section-2
        display: flex
        flex-direction: row
        gap: 0.7rem
    h3
        font-family: base.$codingfont
        color: base.$pinkish
        font-size: 1.5rem
        margin-top: 0.75rem
        margin-bottom: 0.75rem
        line-height: 1.2em
    p
        font-size: 1.2rem
        font-weight: 500
    .responsibilities, .education, .languages, .certification
        font-size: 1.2rem
        padding: 1rem

        li
            font-family: base.$body-font
            line-height: 1.5rem
            text-align: left
            font-weight: 500
        li::marker
            // content: counter(list-item) " › "
            color: base.$pinkish
            animation: color 0.3s ease-in-out infinite
    a
        color: base.$pinkish
        font-family: base.$body-font
