@use  "../../core-ui/_base"

//PORTFOLIO
.portfolio, .portfolio-main
    padding: 1rem 0.75rem 0 0.75rem
    color: #3D1D0C
    display: flex
    flex-direction: column
    gap: 1rem
    align-items: center
    .portfolio-text
        // padding: 1rem 0.75rem 0 0.75rem
        padding-top: 1rem
    h3
        font-family: 'Tenor Sans', sans-serif
        font-size: 2.2rem
        margin-top: 0.75rem
        color: #8C3839
        letter-spacing: 1px
        line-height: 1.2em

    h2
        font-family: 'Tenor Sans', sans-serif
        font-size: 2.5rem
        color: #8C3839
        letter-spacing: 1px
        line-height: 1.2em
    p
        font-size: 1.2rem
    .more-projects-btn
        background-color: transparent
        border-width: 1px
        border-color: base.$pinkish
        border-style: solid
        letter-spacing: 1px
        padding: 1rem
        letter-spacing: 1px
        width: 15rem
        max-width: 50%
        text-align: center
        font-family: base.$body-font
        opacity: 0.9
        background: linear-gradient(to right, base.$white 50%, transparent 50%)
        background-size: 200% 100%
        background-position: right bottom
        transition: all 0.4s ease
    @media (min-width: 450px)
        .portfolio .portfolio-text, .portfolio-main .portfolio-text
            // padding: 1rem 0.75rem 0 0.75rem
            padding: 0
        h2
            font-size: 2.5rem
        .project-img
            max-width: 75%
            max-height: 75%

.portfolio-main
    grid-column: span 3
    padding: 1rem
//PORTFOLIO GRID
.portfolio-grid
    max-width: 100%
    display: flex
    flex-direction: column
    gap: 2rem
    position: relative

    .project
        display: grid
        grid-template-columns: 1fr
        position: relative
        gap: 1rem
        align-items: center
        .project-meta-stack
            display: flex
            flex-direction: row
            flex-wrap: wrap
            gap: 1rem
            font-size: 2rem
            p
                font-size: 1.2rem
                font-weight: 600
                padding: 0.4rem
                font-family: base.$title-font
                background-color: #e3c5c555
                color: base.$white
                backdrop-filter: blur(5px)
                border-radius: 5px
                letter-spacing: 0.5px
                initial-letter: 1px
                margin: 0
    .project-description
        text-weigh: 600
        // padding: 1rem 0.75rem 0 0.75rem
        display: flex
        flex-direction: column
        gap: 1.2rem
        p
            font-size: 1.2rem
    .project-img
        max-width: 100%
        object-fit: cover
        max-height: 100%
        transition: all 0.4s ease
        // opacity: 0.7

    .project-links
        display: flex
        flex-direction: row
        gap: 2rem
        font-size: 1.5rem
        text-decoration: underline
        img
            width: 2.5rem
            height: 2.5rem
    @media (min-width: base.$md-width)
        .portfolio .portfolio-text, .portfolio-main .portfolio-text
            padding: 1rem 0.75rem 0 0.75rem
        h3
            font-size: 2rem

        h2
            font-size: 3rem
        .project
            grid-template-columns: 60% 1fr
            grid-template-rows: 1fr
            .project-description
                padding: 0
        .project-meta-stack
            flex-direction: row

            .project-img
                max-width: 75%
                max-height: 75%

    @media (min-width: base.$lg-width)
        .portfolio-main
            padding: 1rem 0.75rem 0 0.75rem
        .project
            padding: 1rem
            grid-template-columns: repeat(2, 1fr)
            align-items: start
            .project-description
                align-items: flex-start
