// @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;900&display=swap')
// @import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Noto+Sans:wght@300;400&display=swap')
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500&display=swap')
// @import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:opsz,wght@6..96,400;6..96,600;6..96,700&family=Montserrat:wght@100;200;400;700&family=Tenor+Sans&display=swap')
// //Breakpoints
$sm-width: 576px
$md-width: 768px
$lg-width: 992px
$xl-width: 1200px
$xxl-with: 1400px
//Font
// $title-font: 'Barlow', sans-serif
// font-family: 'Bodoni Moda', serif;
// font-family: 'Montserrat', sans-serif;
// font-family: 'Tenor Sans', sans-serif;
$title-font: 'Bodoni Moda', serif
$body-font: 'Montserrat', sans-serif
$codingfont: 'Tenor Sans', sans-serif
//Colors
// change black to #F1EBE6
$black-bg: #F1EBE6
// change pink to #8C3839
$pinkish: #8C3839
// change white to #3D1D0C
$white: #3D1D0C
// change gray to accent #CBA65F or 8C3839
$accent: #CBA65F
$gray: #8C3839
// change dark gray to #3D1D0C
$darkgray: #3D1D0C
//Hide Scrollbar

html::-webkit-scrollbar
    width: 0 !important
    -ms-overflow-style: none
    scrollbar-width: none

body
    background-color: $black-bg
    position: relative
    margin: 0
    padding: 0
    box-sizing: border-box

img
    display: block

a
    text-decoration: none
    cursor: pointer
h1, h2, h3, h4
    font-family: 'Tenor Sans', sans-serif
    margin: 0
    position: relative
ul, li, ol
    padding: 0
    margin: 0
li
    counter-increment: li
    display: list-item
    font-weight: 500
button
    cursor: pointer

p
    font-family: $body-font
    font-weight: 200
    font-size: 1rem
//BASICS
.title-font
    font-family: 'Tenor Sans', sans-serif
    font-size: 1.5rem
.header-font
    font-family: $title-font
.white-text
    color: $white
.pink-text
    color: $pinkish
.gray-text
    color: $gray
    font-size: 1.2rem

// @media screen and (max-width: 500px)
// p
//     font-size: 1.5rem
//     line-height: 1.5rem

// h1
//     font-size: 2rem
//     line-height: 2.5rem

// h2
//     font-size: 1.75rem
//     line-height: 2.25rem

// h3
//     font-size: 1.5rem
//     line-height: 2rem

// h4
//     font-size: 1.25rem
//     line-height: 1.75rem

@media screen and (max-width: 767px)
    p
        font-size: 1rem
        line-height: 1.45rem
        font-weight: 500

    h1
        font-size: 2rem
        line-height: 2.5rem
        font-weight: 700

    h2
        font-size: 1.55rem
        line-height: 2.25rem
        font-weight: 700

    h3
        font-size: 1.35rem
        line-height: 1.5rem
        font-weight: 700

    h4
        font-size: 1.25rem
        line-height: 1.75rem
        font-weight: 700
    
    